/* Basic editor styles */
.tiptap{
	padding: 0.75rem 1rem;
	border: var(--bs-border-width) solid var(--bs-border-color);
}
.tiptap > * + * {
  margin-top: 0.75em;
}

.tiptap ul,
.tiptap ol {
  padding: 0 1rem;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
}

code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;

  code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0D0D0D, 0.1);
}

hr {
  border: none;
  border-top: 2px solid rgba(0,0,0,.5);
  margin: 1rem 0;
}
.tiptap-toolbar{
	margin-bottom:0.25rem;
	display: flex;
}

.tiptap-toolbar--btn-group{
	display: flex;
	margin-right: 0.25rem;
}

.tiptap-toolbar--btn-group button{
	background-color: #FFF;
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-left:0;
	border-right:0;
	padding: .25rem 0.5rem
}
.tiptap-toolbar--btn-group button:first-child{
	border-left: var(--bs-border-width) solid var(--bs-border-color)
}
.tiptap-toolbar--btn-group button:last-child{
	border-right: var(--bs-border-width) solid var(--bs-border-color)
}
  