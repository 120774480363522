#root{
  height: 100dvh;
  max-height: 100dvh;
  display: flex;
  --bs-success-rgb: 33, 213, 155;
  --bs-success: #21D59B;
  --toastify-color-success: var(--bs-success);
  --toastify-color-warning: var(--bs-warning);
  --toastify-color-error: var(--bs-danger);
}

html{
  font-size: 100%;
}
@media screen and (max-width: 767px) {
  html{
    font-size: 90%;
  }
}

/** FONTS **/
.fw-thin      {font-weight: 100}
.fw-extralight{font-weight: 200}
.fw-light     {font-weight: 300}
.fw-regular   {font-weight: 400}
.fw-medium    {font-weight: 500}
.fw-semibold  {font-weight: 600}
.fw-bold      {font-weight: 300}
.fw-extrabold {font-weight: 300}
.fw-black     {font-weight: 300}



/** MISC **/
.pointer{
  cursor: pointer;
}
.hint-text{
  opacity: .8;
}
.overscroll{
  overflow: hidden;
  overflow-y: auto;
}
.overscroll::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
.overscroll::-webkit-scrollbar-track {
	background: rgba(0,0,0,.1);
}

/* Handle */
.overscroll::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
}

/* Handle on hover */
.overscroll::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,.1);
}
/** RESETS **/
a{
  text-decoration: none;
  color:inherit
}
.btn-link{
  padding: 0!important;
}
.btn.btn-sm{
  --bs-btn-padding-x: .9rem;
  --bs-btn-padding-y: .1rem;
  border-radius: .735rem!important;
}
.app{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  max-height: 100dvh;
  height: 100dvh;
}
header{
  box-shadow: 0 3px 6px rgba(0,0,0,.1);
}
main{
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  padding: 0;
  position: relative;
  overflow-y: auto;
}
.main{
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  
}
.main > *{
  width: 100%;
}
.main::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
.main::-webkit-scrollbar-track {
	background: rgba(0,0,0,.1);
}

/* Handle */
.main::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
}

/* Handle on hover */
.main::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,.1);
}
.page{
  max-width: 940px;
  margin: 0 auto;
  height: 100%;
}

/** FORMS **/
.form-control{
  --bs-border-radius: 0;
  padding: .75rem 1rem
}
.form-group{
  --bs-border-radius:0;
}
.form-group label{
  font-size: .8rem
}
.input-group-text .small-input{
  border:0;
  outline: 0;
  padding: 0;
  background-color: transparent;
  width: 35px;
}
.small-select{
  border-radius: 10px;
  border: 0;
  box-shadow: 0 0 5px rgba(0,0,0,.25);
  padding: .25rem .25rem;
}
.small-select select{
  border:0;
  padding-right: 50px;
}
.special-select{
  border:0;
  outline: 0;
}

/** CHECKBOX **/
input[type="checkbox"].big-check{
  opacity:0;
  position:absolute;
}

input[type="checkbox"].big-check + label > span{
  transform: scale(0);
}
input[type="checkbox"].big-check + label{
  height: 50px;
  aspect-ratio: 1;
  border: 1px solid #D7DBEC;
  display: flex;
  margin-bottom: 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"].big-check:checked + label > span{
  transform: scale(1);
  animation: gelatine 0.5s forwards;
}

@keyframes gelatine {
  0% { transform: scale(0, 0);}
  25% { transform: scale(0.9, 1.1); }
  50% { transform: scale(1.1, 0.9); }
  75% { transform: scale(0.95, 1.05); }
}

/** COLORS **/
.bg-light-grey{
  background-color: #f5f6fa;
}
.btn-grey{
  border-color: #d7dbec;
  background-color: #d7dbec;
}
/** SIDEBAR **/
.sidebar{
  min-width: 250px;
  box-shadow: 3px 0px 6px rgba(0,0,0,.1);
  z-index: 9;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px){
  .sidebar{
    position: absolute;
    background-color: #FFF;
    min-width: auto;
    overflow: hidden;
    transition: all 250ms ease;
    width: 100%;
    height: 100%;
    transform: translateX(-100%)
  }

  .sidebar.sidebar-open{
    transform: translateX(0)
  }
}
.sidebar > ul{
  list-style: none;
  padding: 0;
  flex-grow: 1;
}
.sidebar >ul>li{
  padding: 1rem;
  font-size: 1.2rem;
  color:#131523;
  position: relative;
  transition: all 250ms ease;
  cursor: pointer;
}
.sidebar > ul > li.active, .sidebar > ul > li:hover{
  background-color: #f2f7ff;
}
.sidebar > ul > li:before{
  content:"";
  width: 4px;
  height: 100%;
  position: absolute;
  left:-4px;
  top:0;
  background-color: var(--bs-primary);
  border-radius: 1rem;
}
.sidebar > ul > li.active, .sidebar > ul > li:hover{
  transform: translateX(4px);
}
.sidebar > ul > li.active .sidebar-title{
  color: var(--bs-primary)
}
.sidebar-icon{
  color:#7E84A3;
}
.sidebar-notification{
  color:white;
  font-size: 0.8rem;
  background-color: var(--bs-danger);
  border-radius: 1rem;
  height: 20px;
  width: 30px;
  justify-content: center;
  display: flex;
}

/** CHANGELOG **/
ul.changelog{
  list-style: none;
  padding: 10px 0 10px 10px;
}
ul.changelog li{
  position: relative;
  padding: 5px 0 5px 10px;
}
ul.changelog li:before{
  content:"";
  width: 4px;
  height: 80%;
  position: absolute;
  left:-4px;
  top:0;
  background-color: var(--bs-primary);
  border-radius: 1rem;
}
/** TABLE **/
.table-grid{
  border: 1px solid rgba(0,0,0, .1);
}
.table-header{
  display: grid;
  grid-gap: 1rem 1rem;
  padding: 1rem;
  background-color: rgba(0,0,0, .1);
}
.table-total{
  background-color: #F5F6FA;
  display: grid;
}
.table-header span{
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: 600;
  color: #000
}
.table-total span{
  padding: 1rem 0;
  display: block;
  font-size: .8rem;
  color: #000;
}
.table-row{
  display: grid;
  grid-gap: .5rem;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid rgba(0,0,0, .1);
  transition: background-color 250ms ease;
}
.table-row:nth-last-of-type(odd){
  background-color: rgba(0,0,0, .05);
}

.table-row * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 767px) {
  .table-wrapper{
    overflow-x: auto;
  }
  .table-header{
    white-space: nowrap;
  }
  .table-row, .table-header{
    padding: .5rem;
    grid-gap: 2rem;
  }
  .table-body{
    white-space: nowrap;

  }
}
.table-row:first-of-type{
  border-top: 0;
}
.table-row:hover{
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}
/* PAGINATION */
.pagination{
  display: flex;
  border: 1px solid #cecece;
  border-radius: 5px;
}
.pagination span.disabled{
  opacity: .8;
  cursor: initial;
}
.pagination span:first-of-type{
  border-right: 1px solid #cecece
}
.pagination span:last-of-type{
  border-left: 1px solid #cecece
}
.pagination span{
  padding: 5px 10px 3px 10px;
  cursor: pointer;
}
.pagination span.active{
  color: var(--primary);
  border-bottom: 2px solid var(--bs-primary);
}
.status-block{
  background-color: #f5f6fa;
  font-size: .8rem;
  padding: .4rem;
  border-radius: .5rem;
  display: inline-flex;
  align-items: center;
}
/** STATUS BUBBLE **/
.status-bubble{
  background-color: #dadce1;
  font-size: .8rem;
  padding: .4rem;
  border-radius: .5rem;
  display: inline-flex;
  align-items: center;
}
.status-bubble.bg-danger:before{

}
.status-bubble:before{
  content:"";
  display: inline-block;
  margin-right: 5px;
  width:12px;
  height: 12px;
  border: 1px solid black;
  border-radius: 3px;
}
.status-bubble.bg-danger:before{
  border-color: #FFF;
}
.status-bubble.status-checked:before{
  background-color: #000;
}

/** TOGGLE-BUTTONS **/
.toggle-button{
  border:0;
  background: none;
  font-size: .9rem;
  border-radius: .5rem;
  padding: .4rem .8rem;
  transition: background-color 250ms ease;
}
.toggle-button.active, .toggle-button:hover{
  background-color: #f5f6fa;
}

.filter-button{
  border: 1px solid #D7DBEC;
  background-color: white;
  border-radius: 8px;
}

/** CONTEXT MENU **/
.context-menu-wrapper{
  position: relative;
  
}
.context-menu-container{
  position: absolute;
  border-radius: .7rem;
  background: #f5f6fa;
  box-shadow: 0 3px 6px #00000029;
  left:50%;
  transform: translateX(-50%);
  top:100%;
  z-index: 99;
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  margin: 0 auto;
  min-width: 100%;
  
}
.context-menu-container ul{
  list-style: none;
  padding: 0;
  margin: 0;
  width: auto;
  
  max-width: 100%;
  font-size: 0.875rem;
}

.context-menu-container ul li{
  padding: .3rem .6rem;
  cursor: pointer;
  transition: all 100ms ease;
}
.context-menu-container ul li:hover{
  opacity: .75;
}


/** MODAL **/
.modal-close-button{
  position:absolute;
  top: 0;
  right:0;
  padding: 20px;
}
.modal-wrapper, .static-modal{
  position: fixed;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,.3);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 250ms;
  opacity: 0; 
}
.static-modal{
  opacity: 1;
  animation: 250ms 1 alternate spawn
}
@keyframes spawn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.modal-wrapper-enter  {
  opacity: 0;
  transition: all 250ms;
}
.modal-wrapper-enter-active {
  opacity: 1;
  transition: all 250ms;
}
.modal-wrapper-enter-done{
  opacity:1
}
.modal-wrapper-exit-active {
  opacity: 0;
  transition: all 250ms;
}

.modal-container, .static-modal-container{
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,.25);
  padding: 1rem;
  overflow-x:hidden
}
.static-modal-container{
  padding: 2rem;
  width: 400px;
}
.modal-container{
  position: relative;
  overflow-y: auto ;
  transition: all 250ms;
  overflow: visible;
  max-height: 90vh;
  max-width: 80vw;
  transform: translateY(300px);
  opacity: 0;
  transition: all 250ms;
}
.modal-container-enter  {
  transform: translateY(300px);
  opacity: 0;
  transition: all 250ms;
}
.modal-container-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 250ms;
}
.modal-container-exit, .modal-container-enter-done {
  transform: translateY(0);
  opacity: 1;
  transition: all 250ms;
}
.modal-container-exit-active {
  transform: translateY(-300px);
  opacity: 0;
  transition: all 250ms;
}


.modal-container .container{
  height: 100%;
}




/** CHECKBOX **/
.checkbox [type="checkbox"]{
  width:0;
  height: 0;
}
.checkbox{
  align-items: center;
}
.checkbox label{
  font-size: .9rem;
}
.checkbox label > span{
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #D7DBEC;
  margin-right: 10px;
  vertical-align:middle;
  position: relative;
  transition: border 250ms ease;
}
.checkbox label span span{
  position: absolute;
  background-color: #5A607F;
  width: 0%;
  height: 0%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 100ms ease;
}
.checkbox label span span > *{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  color: #FFF;
}
.checkbox [type="checkbox"]:checked + label > span{
 
}
.checkbox [type="checkbox"]:checked + label > span > span{
  width:90%;
  height: 90%;
}


/** CSS Transitions? **/
.opacity-spawn-wrapper{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.1);
  display: flex;
  justify-content: end;
  z-index: 100;
}
.opacity-spawn-container{
  background-color: #FFF;
}
.opacity-spawn-wrapper-enter  {

  opacity: 0;
}
.opacity-spawn-wrapper-enter-active {

  opacity: 1;
  transition: all 250ms;
}
.opacity-spawn-wrapper-exit {

  opacity: 1;
}
.opacity-spawn-wrapper-exit-active {

  opacity: 0;
  transition: all 250ms;
}

/** FILE UPLOAD **/
.file-upload-container{
  width: 100%;
  border: 2px dashed rgba(0,0,0,.4);
  background-color: var(--bg-grey);
  padding: 1rem 0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  transition: .15s ease-in-out;
}
.file-upload-container:hover, .file-upload-container.drag-active{
  box-shadow: inset 0 0 20px rgba(0,0,0,.4);
}
.file-upload-container span{
  color: var(--green-dark);
}
.file-upload-container.highlight{
  border-color: var(--green);
}
.file-wrapper{
  border: 1px solid #cecece;
  border-radius: 4px;
}
.file-item{
  padding: 10px;
  border-bottom: 1px solid #cecece;
}
.file-item:last-of-type{
  border: 0
}
.file-item h6 {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#form-file-upload {
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/** MESSAGES **/
.conversation-item{
  border-bottom: 1px solid rgba(0,0,0,.1);
  position: relative;
  cursor: pointer;
  transition: background-color 250ms ease;
}
.conversation-item.unread{
  background-color: #f5f6fa;
}
.conversation-item:hover, .conversation-item.active, .conversation-item:active{
  background-color: #d7dbec;
}
.conversation-item.unread:before{
  content:"";
  position: absolute;
  top:0;
  left:0;
  width:6px;
  height:100%;
  background-color: var(--bs-primary);
}
.conversation-item.active:before{
  content:"";
  position: absolute;
  top:0;
  left:0;
  width:6px;
  height:100%;
  background-color: var(--bs-success);
}
.message-container{
  display: flex;
  margin-bottom: 2rem;
}
.message-container.message-from-me{
  justify-content: end;
}
.message-bubble-wrapper{
  max-width: 90%;
}
.message-bubble{
  border: 1px solid #d7dbec;
  background-color: #f5f6fa;
  padding: 1rem;
  border-radius: 10px;
  margin: 5px 0;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: pre;
}
.status-bubble select{
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.message-bubble pre, pre.inquirymessage{
  padding:0;
  background-color: transparent;
  color:#000;
  font-family: inherit;
  margin:0;
  font-size:1em;
  overflow-wrap: break-word;
  white-space: break-spaces;
}
.message-bubble pre > p:last-of-type{
  margin:0;
}
.message-container.message-from-me .message-bubble{
  background-color: #d7dbec;
}


@media (max-width: 767px){
  .message-sidebar.chat-open{
    display:none!important
  }
  .message-bubble-wrapper{
    max-width: 100%;
  }
}
.conversation-overlay{
  position: absolute;
  background-color: transparent;
  height: 100%;
  width: 90%;
}
.inactive-flag{
  color:rgba(0,0,0,.1);
}
.message-flag{
  color:rgba(0,0,0,.1);
  transition: color 250ms ease;
}
.message-flag:not(.message-flag-active):hover{
  color: rgba(var(--bs-danger-rgb), .75) !important;
}
.message-flag-active{

}

.ProseMirror{
  max-height: 30vh;
  overflow-y:auto
}
@media (max-width: 767px){
  .messages-wrapper h2{
    font-size: 1.25rem;
  }
  .messages-wrapper .context-menu-container{
    left: initial;
    right:0;
    transform: translateX(0)
  }
  .ProseMirror{
    max-height: 20vh;
    overflow-y:auto
  }
}

.profile-image{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #45a4f8;
  color:#FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.profile-image img{
  width: 100%;
  height:100%;
}
.profile-image > *{

}

/** EXPENSE **/
.expense-bubble{
  font-size: .8rem;
  padding: .4rem;
  border-radius: .5rem;
  display: inline-flex;
  align-items: center;
  --bs-bg-opacity: .5
}

/** NOTIFICATION **/
.notification-wrapper{
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
  padding: 1rem;
  width: 300px;
}
.notification {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  transform: translateX(calc(100% + 1rem));
  max-height: 0px;
  transition: all 250ms ease
}
.notification_show{
  transform: translateX(0);
  max-height: 200px;
  padding: 8px;
  margin-bottom: 1rem;
}

.notification__left {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 8px;
  flex: 1
}
.notification__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: 1
}
.notification__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.notification__description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 0;
}
.notification__success {
  background: #f6fef9;
  border: 1px solid #2f9461;
  border-radius: 8px;
}

.notification__error {
  background: #fffbfa;
  border: 1px solid #cd3636;
  border-radius: 8px;
}
.notification__warning {
  background: #fffcf5;
  border: 1px solid #c8811a;
  border-radius: 8px;
}
.notification__title__success {
  color: #2f9461;
}

.notification__title__warning {
  color: #c8811a;
}
.notification__title__error {
  color: #cd3636;
}
.notification__description__success {
  color: #53b483;
}

.notification__description__warning {
  color: #e9a23b;
}
.notification__description__error {
  color: #f34141;
}
.notification__icon__error {
  color: #cd3636;
}
.notification__icon__success {
  color: #2f9461;
}

.notification__icon__warning {
  color: #c8811a;
}


@keyframes showNotification {
  0%{
    transform: translateX(calc(100% + 1rem))
  }
  100%{
    transform: translate(0)
  }
}

.expense-item .delete-expense{
  position: absolute;
  top:calc(50% - .5rem);
  transform: translate(0%, -50%);
  left: 0;
  opacity:0;
  transition: all 250ms ease;
}

.expense-item:hover .delete-expense{
  transform:translate(-50%, -50%);
  opacity: 1;
}

.tiptap a, .message-bubble a{
  color: rgba(var(--bs-primary-rgb), 1) !important;
  text-decoration: underline;
}


/** DROPDOWN **/
.dropdown{
	display: flex;
  align-items: center;
	justify-content: space-between;
	padding: .75rem .75rem;
	cursor: pointer;
  border: 1px solid #ced4da;
  background: #FFF;
}
.dropdown-container.disabled .dropdown{
  background-color: var(--light-grey);
}
.dropdown.focus{
	border-bottom-width: 0;
}
.dropdown.error, .form-group.error .dropdown{
	border-color: var(--bs-danger);
}

.dropdown.valid, .form-group.valid .dropdown{
	border-color: var(--bs-success);
}
.dropdown p{
	margin: 0;
}
.popper-dropdown{
  z-index: 999;
  
}
.dropdown-options{
	border: 1px solid #ced4da;
	border-top: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
	position: absolute;
	width: 100%;
	top:calc(100%);
  background-color: #f8f9fb;
  z-index: 99;
}
.dropdown-options.opening{

  animation: spawn ease .25s;
  animation-iteration-count: 1;
}
.dropdown-options.closing{

  animation: despawn ease .25s;
  animation-iteration-count: 1;
}

.dropdown-options ul{
	list-style: none;
	margin:0;
	padding: 0;
  max-height: 200px;
  overflow-y: auto;
  overflow-x:hidden;
}

.dropdown-options ul::-webkit-scrollbar {
  width: 10px;
  overflow: hidden;
}

/* Track */
.dropdown-options ul::-webkit-scrollbar-track {
	background: rgba(0,0,0,.1);
}

/* Handle */
.dropdown-options ul::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.1);
}

/* Handle on hover */
.dropdown-options ul::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,.1);
}
.dropdown-options li{
	padding: 10px 10px;
	cursor: pointer;
	transition: background-color 250ms ease;
}
.dropdown-options li:hover{
	background: rgba(var(--bs-primary-rgb), .25);
}
.dropdown-options li.active{
	background: var(--bs-primary);
	color: white
}
.dropdown-enter {
	opacity: 0;
	transform: translateY(-50px)
}
.dropdown-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: all 200ms;
}
.dropdown-exit {
	opacity: 1;
	transform: translateY(0);
}
.dropdown-exit-active {
	opacity: 0;
	transform: translateY(-50px);
	transition: all 200ms;
}

.switch-wrapper{
  transition: all 250ms ease;
  overflow-y: hidden;
  overflow-x: hidden
}
.switch-forwards-enter, .switch-backwards-enter {  opacity: 0;}
.switch-forwards-enter{ transform: translateX(100%) }
.switch-backwards-enter{ transform: translateX(-100%) }

.switch-forwards-enter-active, .switch-backwards-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.switch-forwards-exit, .switch-backwards-exit {
  opacity: 1;
  transform: translateX(0%);
}
.switch-forwards-exit-active, .switch-backwards-exit-active {
  opacity: 0;
  
}
.switch-forwards-exit-active{transform: translateX(100%)}
.switch-backwards-exit-active{transform: translateX(-100%)}

.switch-forwards-enter-active,
.switch-forwards-exit-active,
.switch-backwards-enter-active,
.switch-backwards-exit-active
 {
  transition: opacity 250ms, transform 250ms;
}


@keyframes spawn{
  0% {
      opacity: 0;
      transform:  translate(0px,-40px);
  }
  100% {
      opacity: 1;
      transform:  translate(0px,0px);
  }
}
@keyframes despawn{
  0% {
    opacity: 1;
      transform:  translate(0px, 0px);
  }
  50% {
      opacity: 0;
      transform:  translate(0px,-40px);
  }
  100% {
    opacity: 0;
      transform:  translate(0px,-40px);
  }
}

.loading{
  position: absolute;
  top:0;left:0;right:0;bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #00000050;
  animation: spawnLoading ease .5s;
  animation-iteration-count: 1;
}
@keyframes spawnLoading{
  0% {
      opacity: 0;
      
  }
  100% {
      opacity: 1;
  }
}